import Toolbar from "@mui/material/Toolbar";
import useContentHook from "../../hooks/useContentHook";
// import useGetRegionHook from "../../hooks/useGetRegionHook";
import { tabaqatIntroductionPagesLink } from "../../utils/StaticVariables";
import { useDispatch } from "react-redux";
import { navbarContentArr } from "../../utils/StaticVariables";
import { setChangeLanguage } from "../../Redux/sliceReducers/TranslationSlice";
import { getStaticAssetURL } from "../../utils/helperFuncations";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const openInNewTab = () => {
  window.open(tabaqatIntroductionPagesLink, "_blank", "noopener,noreferrer");
};

const ContentNav = (props) => {
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();

  const { getContentText } = useContentHook();
  // const getRegion = useGetRegionHook();

  const changeRouteHandel = (url) => {
    props.mob && props.openHandel();
    switch (url) {
      case "main":
        openInNewTab();
        break;
      case false:
        dispatch(setChangeLanguage());
        break;
      default:
        navigate(`${url}`);
    }
  };

  const isSelectedCheck = (url, path) => (
      url === path||
      (path.replaceAll("/") === "/" && url === "")
    );


  const listStyle = (url, path) => {
    return {
      background: isSelectedCheck(url, path) ? "white" : "inherit",
      color: isSelectedCheck(url, path) ? "secondary.main" : "inherit",
    };
  };

  const iconStyle = (url, path) => {
    return {
      color: isSelectedCheck(url, path) ? "secondary.main" : "white",
    };
  };

  return (
    <Box sx={boxStyle}>
      <Toolbar sx={logoStyle} onClick={() => changeRouteHandel("")}></Toolbar>

      <List sx={{ pt:  1}}>
        {navbarContentArr.map((el) => (
          <ListItem
            sx={listStyle(el.url, location.pathname)}
            key={el.url}
            disablePadding
            onClick={() => {
              changeRouteHandel(el.url);
            }}
          >
            <ListItemButton
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              <ListItemText
                primary={getContentText(el.text)}
                sx={{ textAlign: "start" }}
              />
              <ListItemIcon sx={iconStyle(el.url, location.pathname)}>
                {el.icon}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ContentNav;

const logoStyle = {
  backgroundImage: `url(${getStaticAssetURL("logo.png")})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundColor: "#fff",
  cursor: "pointer",
};

const boxStyle = {
  bgcolor: "secondary.main",
  color: "#fff",
  height: "100vh",
  overflow: "auto",
};
