import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import TopNav from "../Components/Navbar/TopNav";
import SideNav from "../Components/Navbar/SideNav";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  setMyKeys,
  changechosenAssessKey,
} from "../Redux/sliceReducers/keySlice";

const drawerWidth = 240;

function Home() {
  const dispatch = useDispatch();
  const { token, ApiUrl } = useSelector((state) => state.globalVars);
  const [mobileOpen, setMobileOpen] = useState(false);

  const openHandel = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (token.Authorization !== "Bearer undefined") {
      axios
        .get(`${ApiUrl}secrets/v1/keys/get-all-keys`, {
          headers: token,
        })
        .then((res) => {
          dispatch(setMyKeys({ keyArr: res.data }));
          dispatch(
            changechosenAssessKey({
              chosenAssessKey: res.data.find((el) => el.is_active)
                ?.generated_keys,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dispatch, ApiUrl, token]);

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <TopNav openHandel={openHandel} drawerWidth={drawerWidth} />

      <SideNav
        openHandel={openHandel}
        mobileOpen={mobileOpen}
        drawerWidth={drawerWidth}
      />

      <Box component="main" sx={BoxStyle}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Home;

const BoxStyle = {
  flexGrow: 1,
  p: 3,
  width: { xs: "100vw", md: `calc(100% - ${drawerWidth}px)` },
};
