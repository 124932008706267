// nav icons
import KeyIcon from "@mui/icons-material/Key";
import SpeedIcon from "@mui/icons-material/Speed";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";

export const monthsArr = [
  {
    val: 1,
    label: "months_month1",
  },
  { val: 2, label: "months_month2" },
  { val: 3, label: "months_month3" },
  { val: 4, label: "months_month4" },
  { val: 5, label: "months_month5" },
  { val: 6, label: "months_month6" },
  { val: 7, label: "months_month7" },
  { val: 8, label: "months_month8" },
  { val: 9, label: "months_month9" },
  { val: 10, label: "months_month10" },
  { val: 11, label: "months_month11" },
  { val: 12, label: "months_month12" },
];

export const navbarContentArr = [
  {
    text: "sideNav_platform",
    url: "/",
    icon: <DashboardOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_keys",
    url: "/keys",
    icon: <KeyIcon sx={{ marginLeft: " auto", transform: "rotate(130deg)" }} />,
  },
  {
    text: "sideNav_basemaps",
    url: "/base-maps",
    icon: <MapOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_mapservices",
    url: "/map-services",
    icon: <ExploreOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_geocoding",
    url: "/geocoding",
    icon: <AddLocationAltOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_plan",
    url: "/plan",
    icon: <AttachMoneyOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_usage",
    url: "/usage",
    icon: <SpeedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_contact",
    url: "/contact-us",
    icon: <CallOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "pages_documentation_heading",
    url: "/documentation",
    icon: <ArticleOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_landing",
    url: "main",
    icon: <FileOpenOutlinedIcon sx={{ marginLeft: " auto" }} />,
  },
  {
    text: "sideNav_language",
    url: false,
    icon: <LanguageIcon sx={{ marginLeft: " auto" }} />,
  },
];

export const contactInfoArr = [
  {
    label: "contact_name",
    name: "full_name",
  },
  {
    label: "contact_email",
    name: "email",
  },
  {
    label: "contact_num",
    name: "phone_number",
    phone: true,
  },
  {
    label: "contact_des",
    name: "body",
    multi: true,
  },
];

export const contactInitialVals = {
  full_name: "",
  email: "",
  phone_number: "",
  body: "",
};

export const countries = [
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AT", label: "Austria", phone: "43" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
  },
  { code: "CN", label: "China", phone: "86" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
  },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "FR", label: "France", phone: "33" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GR", label: "Greece", phone: "30" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  { code: "IN", label: "India", phone: "91" },
  { code: "IQ", label: "Iraq", phone: "964" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JO", label: "Jordan", phone: "962" },
  { code: "JP", label: "Japan", phone: "81" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PS", label: "Palestine, State of", phone: "970" },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "SY",
    label: "Syrian ",
    phone: "963",
  },
  { code: "TR", label: "Turkey", phone: "90" },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "ZA", label: "South Africa", phone: "27" },

  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

export const typeFilterData = [
  {
    val: "premium",
    label: "filter_premium",
  },
  {
    val: "subscriber",
    label: "filter_subscriber",
  },
];

export const restrictionTypeArr = [
  { value: "none", label: "restriction_types_none" },
  { value: "http", label: "restriction_types_http" },
  { value: "ip", label: "restriction_types_ip" },
];

export const mapServicesTypes = ["WMS", "WFS"];
export const staticLoadingArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const freePlanId = process.env.REACT_APP_freePlanId;


//* apis links
export const catalogTabaqatApi =
process.env.NODE_ENV === "production"
? process.env.REACT_APP_API_URL_PRODUCTION + "items"
: process.env.REACT_APP_API_URL_DEVELOPMENT + "items";

export const tabaqatKayLayer = process.env.REACT_APP_tabaqatKayLayer;
export const tabaqatKeyBaseMap = process.env.REACT_APP_tabaqatKeyBaseMap;
export const tabaqatKeyAutoComplete = process.env.REACT_APP_tabaqatKeyAutoComplete ;
export const geocodingUrl = "https://geocoder.tabaqat.net/v1/autocomplete"
export const docsUrl = "https://docs.tabaqat.net/"
export const terriaUrl = "https://terria-2.tabaqat.net"
export const basemapUrl = "https://basemaps.tabaqat.net"
export const geoServerUrl = "https://data.tabaqat.net/geoserver/"
export const contaboStorageURL = "https://eu2.contabostorage.com/"
export const tabaqatIntroductionPagesLink = "https://tabaqat.net/"
