import { Typography, Stack, Box, Grid, Card, CardContent } from "@mui/material";
import useContentHook from "../hooks/useContentHook";
import { getStaticAssetURL } from "../utils/helperFuncations";
const Platform = () => {
  const { getContentText } = useContentHook();

  return (
    <Box>
      {/* header section  */}
      <Grid container sx={headerStyle}>
        {/* text header section area  */}
        <Grid item lg={6} md={12} sm={7}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            sx={textAreaStyle}
          >
            <Typography variant="h1" sx={paragraphStyle}>
              {getContentText("header_title")}
            </Typography>
            <Typography variant="h5" sx={descriptionStyle}>
              {getContentText("header_des")}
            </Typography>
          </Stack>
        </Grid>
        {/* gif image at header section  */}
        <Grid item lg={6} md={12} sm={5} sx={gifBoxStyle}>
          <img
            src={getStaticAssetURL("saudi_man.gif")}
            alt="platform platform"
          />
        </Grid>
      </Grid>

      {/* list section  */}

      <Box sx={{ my: 5 }}>
        <Typography variant="h5" component="h5" color="secondary">
          {getContentText("pages_platform_heading")}
        </Typography>

        <Card sx={{ mt: 2 }}>
          <CardContent component="ul" sx={{ pl: 6, py: 2 }}>
            {getContentText("pages_platform_terms")}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Platform;

const headerStyle = {
  py: 5,
  px: { xs: 1, xl: 2 },
  backgroundImage: `url(${getStaticAssetURL("general_topbar.png")})`,
  backgroundSize: "cover",
  backgroundPosition: "bottom",
  borderRadius : 1 , 
};

const textAreaStyle = {
  color: "#fff",
  height: "100%",
  px: { xs: 1 },
};

const paragraphStyle = {
  fontSize: { xs: "2.7rem", lg: "3.8rem" },
  fontWeight: "600",
};

const descriptionStyle = {
  pb: { xs: 3, sm: 0 },
  fontSize: { xs: "1rem", xl: "1.2rem" },
};

const gifBoxStyle = {
  p: 2,
  width: "100%",
  maxHeight: "300px",
  "& img": {
    maxWidth: "100%",
    margin: "auto ",
    display: "block",
    maxHeight: "100%",
  },
};

